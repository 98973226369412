import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { isIOS, isIOS13, isMobile } from 'react-device-detect';
import { useCookies } from 'react-cookie';
import { Button } from 'react-bootstrap';

import CentralFrame from 'shared-components/components/CentralFrame';
import { environment, githash, Method, Service } from 'shared-components/configuration';
import useSWQuery from 'shared-components/hooks/useSWQuery';
import { useGateway } from 'providers/GatewayProvider';
import { BoxContainer } from 'components/PaymentFlow/components/BoxContainer';
import {
  useSWCallbackAccountEvent,
  useSWCallbackErrorEvent,
  useSWCallbackPaymentEvent,
  useSWCallbackSelectedEvent,
  useSWCallbackSignEvent,
  useSWCallbackStateEvent,
  useSWCallbackUpdateEvent
} from 'shared-components/providers/SWEventProvider';
import {
  deepCompare,
  escapeJSON,
  generateCryptoKeys,
  getJWK,
  getPrivKeyCookie,
  getThumbprint,
  isValidUrl,
  parseJwt,
  signData
} from 'shared-components/utils';
import { StateDataType } from 'shared-components/types/Notifications';
import { usePrevious } from 'utils';
//import { useHash } from 'shared-components/hooks/useHash';
import { CANCEL_PAYMENT, CREATE_SESSION } from 'shared-components/queries/mutations';
import {
  GATEWAY_BANKINGHEALTH,
  GATEWAY_MERCHANTINFO,
  GET_SESSION,
  GW_RFQ,
  RESUME_SESSION,
  SIGNAL_COUNT
} from 'shared-components/queries/graphql';
import { BankList } from 'utils/const';
import { SEvent, State } from 'utils/stateMachine';
import { StateMachineContext } from 'providers/StateMachineProvider';

import { GatewayWidget } from './GatewayWidget';
import { Success } from './Success';
import { Fail } from './Fail';

let pingTimer: any;
let recoverTimer: any;

const title = {
  [State.UNKNOWN]: '',
  [State.IDLE]: 'Checking connections',
  [State.OLD_STATE_RECOVERY]: '',
  [State.PID_DISCOVERY]: 'Personal ID Discovery',

  [State.PRE_BANK_HEALTH_CHECK]: 'Checking connections',
  [State.BANK_HEALTH_CHECK]: 'Checking connections',
  [State.PID_ACK]: 'waiting KYC verification',

  [State.SELECT_BANK]: 'Select your bank:',
  [State.REQUESTED]: 'Select your bank:',
  [State.RESOLVE_MERCHANT_BY_ID]: 'Select your bank:',

  [State.BANK_AUTHENTICATE]: 'Authenticate',
  [State.MONITOR_AUTH]: 'Authenticate',
  [State.AUTH_USER_INTO]: 'Authenticate',

  [State.ACCOUNT_CHOOSE]: 'Select payment account:',
  [State.LIST_ACCOUNTS]: 'Select payment account:',
  [State.SELECT_USER_ACCOUNT]: 'Select payment account:',

  [State.ADD_RECIPIENT]: 'Add Recipient',

  [State.SIGN]: 'Sign the payment',
  [State.SIGN_TX]: 'Sign the payment',
  [State.MONITOR_SIGN_TX]: 'Sign the payment',

  [State.NEED_UPGRADE]: 'Upgrade account',
  [State.SUCCESS]: '',
  [State.ERROR]: '',
  [State.AWAITING_FINALITY]: ''
};

const description = {
  [State.UNKNOWN]: '',
  [State.IDLE]: '',
  [State.OLD_STATE_RECOVERY]: '',
  [State.PRE_BANK_HEALTH_CHECK]: '',
  [State.BANK_HEALTH_CHECK]: '',
  [State.PID_DISCOVERY]: isMobile
    ? 'Open BankID on your device by pressing the button'
    : 'Start the BankID app and scan the QR',

  [State.PID_ACK]: '',

  [State.SELECT_BANK]: '',
  [State.RESOLVE_MERCHANT_BY_ID]: 'Querying merchant',
  [State.REQUESTED]: 'Connecting to selected bank',

  [State.BANK_AUTHENTICATE]: isMobile
    ? 'Open BankID on your device by pressing the button'
    : 'Start the BankID app and scan the QR',

  [State.MONITOR_AUTH]: isMobile
    ? 'Open BankID on your device by pressing the button'
    : 'Start the BankID app and scan the QR',

  [State.AUTH_USER_INTO]: 'Authenticating user into bank',

  [State.ACCOUNT_CHOOSE]: '',
  [State.LIST_ACCOUNTS]: 'Downloading account data',
  [State.SELECT_USER_ACCOUNT]: 'Select account to pay',

  [State.ADD_RECIPIENT]: isMobile
    ? 'Open BankID on your device by pressing the button'
    : 'Start the BankID app and scan the QR',

  [State.SIGN]: isMobile ? 'Open BankID on your device by pressing the button' : 'Start the BankID app and scan the QR',

  [State.SIGN_TX]: isMobile
    ? 'Open BankID on your device by pressing the button'
    : 'Start the BankID app and scan the QR',

  [State.MONITOR_SIGN_TX]: isMobile
    ? 'Open BankID on your device by pressing the button'
    : 'Start the BankID app and scan the QR',

  [State.NEED_UPGRADE]:
    'To add a new recipient or continue with Payment Initation, activate your Mobile BankID for extended use.',

  [State.SUCCESS]: '',
  [State.ERROR]: '',
  [State.AWAITING_FINALITY]: ''
};

const GatewayData = () => {
  const cookieExpire = useMemo(() => {
    const date = new Date(); // Current date
    const newDate = new Date(date.getTime() + 30 * 24 * 60 * 60 * 1000);
    return newDate;
  }, []);

  const [_cookie, setCookie] = useCookies(['pid', 'key']);
  const { authToken /*, refId, depositAmount,*/ /*bankData*/ } = useParams();
  const [step, setStep] = useState(0);
  const [cancelLocked, setCancelLocked] = useState<boolean>(false);
  const coreData = useGateway();
  const connected = StateMachineContext.useSelector((state) => state.context.wsConnected);
  const oldState = StateMachineContext.useSelector((state) => state.context.oldState);
  const [stateToSend, setStateToSend] = useState<any>(oldState);
  const gqlPrevStatus = usePrevious(connected);
  const [searchParams] = useSearchParams();
  const [dataRecovered, setDataRecovered] = useState<boolean>(false);
  const [signalsRecovered, setSignalsRecovered] = useState<boolean>(false);

  useEffect(() => {
    if (searchParams.get('sessionId') !== null) {
      coreData.setters.setSessionId(searchParams.get('sessionId')!);
    }
  }, [coreData.setters, searchParams]);

  const merchantId = useMemo(() => {
    if (authToken !== undefined) {
      const jwt = parseJwt(authToken);
      return jwt.merchant_id;
    }
    return;
  }, [authToken]);

  const merchantInfoQuery = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.GATEWAY,
    returnObjectName: 'merchantInfo',
    data: {
      signed_challenge: authToken,
      session_id: coreData.values.sessionId,
      query: GATEWAY_MERCHANTINFO,
      variables: {}
    },
    auto: false,
    onResponse: (data: any) => {
      coreData.setters.setRecipientName(data.name);
      coreData.setters.setRecipientIban(data.iban);
    }
  });

  useEffect(() => {
    if (deepCompare(stateToSend, coreData.values.sessionData)) {
      return;
    }
    if (stateToSend.state !== 'undefined' && stateToSend.state !== State.OLD_STATE_RECOVERY) {
      coreData.setters.setSessionData(stateToSend);

      /*
      setSessionData.execute({
        variables: {
          sessionId: coreData.values.sessionId,
          data: JSON.stringify(stateToSend)
        }
      });

      */
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateToSend]);

  const processSignEvent = useCallback(
    (signEventData: any) => {
      console.log('PROCESS SIGN EVENT');
      console.log(signEventData);
      if (signEventData === undefined) {
        return;
      }
      if (signEventData.txStatus == 'completed') {
        coreData.values.stateRef.send({ type: SEvent.COMPLETED });
      }
      if (signEventData.paymentAuthStatus == 'failed') {
        coreData.values.stateRef.send({ type: SEvent.FAIL });
      }

      if (signEventData.paymentAuthStatus == 'accepted') {
        //coreData.setters.setState(State.SIGN);
        //      window.location.href = isValidUrl(signEventData?.url) ? signEventData?.url : settleReturnSuccessUrl;
      }
      if (signEventData.paymentAuthStatus == 'requested') {
        //      window.location.href = isValidUrl(signEventData?.url) ? signEventData?.url : settleReturnSuccessUrl;
      }

      if (signEventData.paymentAuthStatus == 'authReqd' && signEventData.token.length > 0) {
        if (signEventData.token.length > 300) {
          coreData.setters.setToken(`data:image/png;base64,${signEventData.token}`);
        } else {
          coreData.setters.setToken(signEventData.token);
        }
      }

      if (signEventData.paymentAuthStatus == 'recipMissing') {
        coreData.values.stateRef.send({ type: SEvent.ADD_RECIPIENT });
      }
      if (signEventData.paymentAuthStatus === 'requestAgain') {
        coreData.setters.setToken('');
        coreData.values.stateRef.send({ type: SEvent.NEXT });
        //coreData.setters.setState(State.SIGN_TX);
      }

      if (signEventData.paymentAuthStatus == 'awaitingFinality') {
        setCancelLocked(true);
        coreData.values.stateRef.send({ type: SEvent.COMPLETED });
      }

      if (
        signEventData.paymentAuthStatus == 'authDone' ||
        signEventData.paymentAuthStatus == 'completed'
        //&& (coreData.values.bank === 'seb' || coreData.values.bank === 'handelsbank')
      ) {
        setCancelLocked(true);
        //coreData.setters.setErrorString('Payment processing in progress, you may return to the merchant site');
        coreData.values.stateRef.send({ type: SEvent.COMPLETED });
        //coreData.setters.setState(State.SUCCESS);
      }

      if (signEventData.paymentAuthStatus == 'authDone' && coreData.values.authMode === 'qr') {
        coreData.setters.setToken('');
      }
      if (signEventData.paymentAuthStatus == 'needUpgrade') {
        const url = signEventData.url;
        if (isValidUrl(url)) {
          coreData.setters.setRedirectUrl(url);
          coreData.values.stateRef.send({ type: SEvent.NEED_UPGRADE });
          //          coreData.setters.setState(State.NEED_UPGRADE);
        }
      }
      if (signEventData.paymentAuthStatus == 'requestAgain') {
        // DO SOME MESSAGE LIKE "PI is about to resume"
      }
    },
    [coreData.setters, coreData.values.authMode, coreData.values.stateRef]
  );

  const resumeSession = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.GATEWAY,
    returnObjectName: 'resumeSignals',
    data: {
      signed_challenge: authToken,
      session_id: coreData.values.sessionId,
      query: RESUME_SESSION,
      variables: {
        merchantId: merchantId
      }
    },
    onResponse: (value) => {
      console.log('RESUME SIGNALS RESPONSE');
      clearInterval(recoverTimer);
      recoverTimer = undefined;
      console.log(value);
      setSignalsRecovered(true);
    },
    onErrors: (value) => {
      console.log('RESUME SIGNALS ERRORS');
      console.log(value);
    },
    auto: false
  });

  const signalCounter = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.GATEWAY,
    returnObjectName: 'resumeSession',
    data: {
      signed_challenge: authToken,
      session_id: coreData.values.sessionId,
      query: SIGNAL_COUNT,
      variables: {
        merchantId: merchantId
      }
    },
    auto: false
  });

  const ping = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.GATEWAY,
    returnObjectName: 'nonexistent',
    data: {
      signed_challenge: authToken,
      session_id: coreData.values.sessionId,
      query: '{ ping }'
    },
    auto: false
  });
  /*
  const onVisibilityChange = useCallback(() => {
    if (document.visibilityState === 'visible') {
      //ping.execute();
    }
  }, [ping]);
*/
  /*
useEffect(() => {
    document.addEventListener('visibilitychange', onVisibilityChange);

    return () => document.removeEventListener('visibilitychange', onVisibilityChange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
*/

  const dataRecovery = useCallback(
    (data: any) => {
      console.log(data);
      coreData.values.stateRef.send({ type: data.state });
      coreData.setters.setAccountList(data.accounts);
      coreData.setters.setRecipientIban(data.recipientIban);
      coreData.setters.setRecipientName(data.recipientName);
      coreData.setters.setAmount(data.amount);
      coreData.setters.setOperationId(data.operationId);
      if (data.state === State.SIGN) {
        processSignEvent(data.signState);
      }
      setDataRecovered(true);
    },
    [coreData.setters, coreData.values.stateRef, processSignEvent]
  );

  const rfqQuery = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.GATEWAY,
    returnObjectName: 'rfq',
    data: {
      signed_challenge: authToken,
      session_id: coreData.values.sessionId,
      query: GW_RFQ,
      variables: {
        txRef: coreData.values.refId
      }
    },
    auto: false,
    onResponse: (data: any) => {
      const rfq = data?.edges?.map((value: any) => {
        return value.node;
      })?.[0];
      if (rfq === undefined) {
        coreData.setters.setErrorString('Payment process failed - tx not found');
        coreData.values.stateRef.send({ type: SEvent.FAIL });
      }
      coreData.setters.setPid(rfq.clientPid === '0' ? '' : rfq.clientPid);
      if (rfq.sekDeposit === rfq.eurRequest) {
        coreData.setters.setCurrency('SEK');
        coreData.setters.setAmount(rfq.sekDeposit);
      } else {
        coreData.setters.setCurrency('EUR');
        coreData.setters.setAmount(rfq.eurRequest);
      }
      coreData.values.stateRef.send({ type: SEvent.BANKS_HEALTHY });
    }
  });

  useEffect(() => {
    coreData.setters.setAuthMode(isMobile ? 'ast' : 'qr');
  }, [coreData.setters]);

  /*
  useEffect(() => {
    if (bankData === undefined) {
      //      if (searchParams.get('kycVerify') === 'true') {
      //        coreData.values.stateRef.send({ type: SEvent.UPDATE_DATA, data: { manualVerify: true } });
      //      }
      if (searchParams.get('token') === 'true' && cookie.pid) {
        coreData.setters.setPid(cookie.pid.toString());
        setCookie('pid', cookie.pid.toString(), {
          path: '/',
          expires: cookieExpire,
          httpOnly: false,
          secure: false
        });
      }
    } else {
      coreData.setters.setPid(bankData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankData, searchParams, cookie, cookieExpire]);
*/
  useEffect(() => {
    console.log('ISIOS13');
    console.log(isIOS13);
    console.log('ISIOS');
    console.log(isIOS);
    if (authToken === undefined) {
      return;
    }
    coreData.setters.setAuthToken(authToken);
    const tokenData = parseJwt(authToken);
    if (tokenData === undefined) {
      coreData.setters.setErrorString('Wrong token data');
      coreData.values.stateRef.send({ type: SEvent.FAIL });
    }
    coreData.setters.setRefId(tokenData.tx_ref);
    if (coreData.values.pid === undefined || coreData.values.pid === '' || coreData.values.pid === '0') {
      coreData.setters.setPid(tokenData.client_id);
    }

    if (searchParams.get('token') === 'true' && getPrivKeyCookie(coreData.values.pid) !== null) {
      const key = getPrivKeyCookie(coreData.values.pid);
      if (key === null) {
        return;
      }
      signData(coreData.values.pid, key, { challenge: authToken }).then((value) => {
        coreData.setters.setAuthToken(value);
      });
    } else {
      coreData.setters.setAuthToken(authToken);
    }
  }, [authToken, coreData.values.pid, searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

  const bankHealth = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.GATEWAY,
    returnObjectName: 'bankingHealth',
    data: {
      signed_challenge: authToken,
      session_id: coreData.values.sessionId,
      query: GATEWAY_BANKINGHEALTH,
      variables: {}
    },
    auto: false,
    onResponse: (data: any) => {
      try {
        const healthData = JSON.parse(data);
        const banks = healthData.getBankingHealth;
        for (const element of banks) {
          const id = BankList.findIndex((bank) => element.bank === bank.name);
          BankList[id].healthy = element.healthy;
          /* temporary disable on desktop */
          /*
          const swedid = BankList.findIndex((bank) => 'swedbank' === bank.name);
          if (isDesktop) {
            BankList[swedid].healthy = false;
          }
          
        */
          /* temporary disable on desktop */
        }
        const hasTrue = BankList.find((bank) => bank.healthy === true);
        if (hasTrue === undefined) {
          coreData.setters.setErrorString('Service unavailable. Please try again later');
          coreData.values.stateRef.send({ type: SEvent.BANKS_UNHEALTHY });
        } else {
          rfqQuery.execute({
            variables: {
              txRef: coreData.values.refId
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    onErrors: (data: any) => {
      console.log(data);
    }
  });
  const getSessionData = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.GATEWAY,
    returnObjectName: 'session',
    data: {
      signed_challenge: authToken,
      session_id: coreData.values.sessionId,
      query: GET_SESSION,
      variables: {}
    },
    auto: false,
    onResponse: (data: any) => {
      if (data === null) {
        const update = {
          oldState: {
            state: 'new'
          }
        };
        coreData.values.stateRef.send({ type: SEvent.UPDATE_DATA, data: update });
      } else {
        const sessionData = JSON.parse(data.data);
        const update = {
          oldState: sessionData.data
        };
        coreData.values.stateRef.send({ type: SEvent.UPDATE_DATA, data: update });
      }
    }
  });

  const _setSessionData = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.GATEWAY,
    returnObjectName: 'storeSession',
    data: {
      signed_challenge: authToken,
      session_id: coreData.values.sessionId,
      query: CREATE_SESSION,
      variables: {}
    },
    auto: false,
    onResponse: (data: any) => {
      console.log(data);
    }
  });

  const cancelPayment = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.GATEWAY,
    returnObjectName: 'cancelPay',
    data: {
      signed_challenge: authToken,
      session_id: coreData.values.sessionId,
      query: CANCEL_PAYMENT
    },
    auto: false,
    onResponse: (data: any) => {
      if (data.status === true) {
        coreData.setters.setErrorString('Payment process cancelled');
        coreData.values.stateRef.send({ type: SEvent.FAIL });
      } else {
        //coreData.values.stateRef.send({ type: SEvent.BACK });
      }
    }
  });

  const sendKeyQuery = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.GATEWAY,
    returnObjectName: '',
    data: {
      signed_challenge: authToken,
      session_id: coreData.values.sessionId
    },
    auto: false
  });

  const sendKey = useCallback(async () => {
    let key = await getJWK(coreData.values.pid);
    if (key === null) {
      generateCryptoKeys(coreData.values.pid);
    }
    key = await getJWK(coreData.values.pid);
    const thumb = getThumbprint(coreData.values.pid);
    const keyEscaped = escapeJSON(JSON.stringify(key));
    console.log(thumb);
    console.log(keyEscaped);
    sendKeyQuery.execute({
      query: `mutation { submitKey(keyId: "${thumb}", key: "${keyEscaped}") { status } }`
    });
  }, [coreData.values.pid, sendKeyQuery]);

  useEffect(() => {
    if (searchParams.get('token') !== 'true') {
      return;
    }
    switch (coreData.values.state) {
      case State.SUCCESS: {
        if (searchParams.get('token') === 'true') {
          sendKey();
          setCookie('pid', coreData.values.pid.toString(), {
            path: '/',
            expires: cookieExpire,
            httpOnly: false,
            secure: false
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookieExpire, coreData.values.pid, coreData.values.state, searchParams]);

  useEffect(() => {
    if (connected === true) {
      ping.execute();
      coreData.values.stateRef.send({ type: SEvent.UPDATE_DATA, data: { connectToWebsocket: true } });
      if (pingTimer === undefined) {
        console.log('PING SET!!!');
        pingTimer = setInterval(() => {
          ping.execute();
        }, 15_000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connected]);

  useEffect(() => {
    if (
      connected === true &&
      coreData.values.authToken.length > 0 &&
      coreData.values.state === State.BANK_HEALTH_CHECK
    ) {
      bankHealth.execute();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coreData.values.state, coreData.values.authToken.length, connected]);
  /*
  useEffect(() => {
    if (coreData.values.operationId !== undefined) {
      console.log(coreData.values.operationId);
      setStateToSend((old: any) => ({
        ...old,
        operationId: coreData.values.operationId
      }));
    }
  }, [coreData.values.operationId]);
*/
  useEffect(() => {
    setStateToSend((old: any) => ({
      ...old,
      operationId: coreData.values.operationId,
      recipientIban: coreData.values.recipientIban,
      recipientName: coreData.values.recipientName,
      amount: coreData.values.amount,
      state: coreData.values.state
    }));
    console.log(coreData.values.state);
    switch (coreData.values.state) {
      case State.IDLE: {
        //dataRecovery(oldState);
        // Reset all locks
        break;
      }
      case State.PRE_BANK_HEALTH_CHECK: {
        //dataRecovery(oldState);
        break;
      }
      case State.BANK_HEALTH_CHECK: {
        merchantInfoQuery.execute();

        break;
      }
      case State.OLD_STATE_RECOVERY: {
        //dataRecovery(oldState);
        break;
      }
      case State.PID_DISCOVERY: {
        setStep(1);
        // Do the PID DISCOVERY WIDGET
        break;
      }
      case State.PID_ACK: {
        setStep(1);
        // Do the PID DISCOVERY WIDGET
        break;
      }

      case State.SELECT_BANK: {
        setStep(2);
        break;
      }

      case State.RESOLVE_MERCHANT_BY_ID:
      case State.REQUESTED: {
        break;
      }

      case State.MONITOR_AUTH:
      case State.AUTH_USER_INTO:
      case State.BANK_AUTHENTICATE: {
        setStep(3);
        // bank authenticate screen
        break;
      }

      case State.LIST_ACCOUNTS:
      case State.SELECT_USER_ACCOUNT:
      case State.ACCOUNT_CHOOSE: {
        coreData.setters.setToken('');
        setStep(4);
        break;
      }
      case State.SIGN_TX:
      case State.MONITOR_SIGN_TX:
      case State.SIGN: {
        setStep(5);
        //        resumeSession.execute({});

        // sign the payment
        break;
      }
      case State.SUCCESS: {
        //        setStep(6);
        // payment success
        break;
      }
      /*
      case State.ERROR:
        const url = new URL(settleReturnFailureUrl);
        if (coreData.values.errorString.length > 0) {
          url.searchParams.set('errorString', coreData.values.errorString);
        }
        window.location.href = url.toString();
        break;
        */

      default: {
        break;
      }
    }
    // STATE MACHINE
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coreData.values.state]);

  useEffect(() => {
    if (
      searchParams.get('sessionId') !== undefined &&
      searchParams.get('sessionId') !== null &&
      searchParams.get('sessionId')!.length > 0
    ) {
      const sessionD = searchParams.get('sessionData');
      if (sessionD) {
        const jsonStringified = atob(sessionD);
        const sessionData = JSON.parse(jsonStringified);
        const update = {
          oldState: sessionData
        };
        coreData.values.stateRef.send({ type: SEvent.UPDATE_DATA, data: update });
      } else {
        getSessionData.execute({ variables: { sessionId: searchParams.get('sessionId') } });
      }
    } else {
      const update = {
        oldState: {
          state: 'new'
        }
      };
      coreData.values.stateRef.send({ type: SEvent.UPDATE_DATA, data: update });
      //        coreData.values.stateRef.send({ type: SEvent.NEXT });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    if (connected === true && coreData.values.refId !== undefined && gqlPrevStatus === false) {
      try {
        const tokenData = parseJwt(authToken!);

        if (tokenData.exp < Math.floor(Date.now() / 1000)) {
          coreData.setters.setErrorString('Token has expired');
          coreData.values.stateRef.send({ type: SEvent.FAIL });
          return;
        }
      } catch (error) {
        console.error(error);
      }
    } else if (connected === false) {
      console.log('PING CLEAR!!!');
      clearTimeout(pingTimer);
      pingTimer = undefined;

      if (connected === false && gqlPrevStatus === true) {
        //const tokenData = parseJwt(coreData.values.authToken);
        /*
        if (tokenData.exp < Math.floor(Date.now() / 1000)) {
          coreData.setters.setErrorString('Token has expired');
          coreData.values.stateRef.send({ type: SEvent.FAIL });
          return;
        }
*/
        //coreData.setters.setErrorString('Connection with gateway closed.');
        //console.log('Reconnecting');
        //coreData.values.stateRef.send({ type: SEvent.FAIL });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connected, coreData.values.refId, coreData.values.state, gqlPrevStatus, searchParams]);

  useSWCallbackAccountEvent((data: any) => {
    console.log(data);
    console.log(coreData.values.operationId);
    if (data?.task === coreData.values.operationId) {
      const accounts = data.accounts.sort((a: any, b: any) => {
        return Number(a.balance) < Number(b.balance) ? 1 : -1;
      });
      setStateToSend((old: any) => ({
        ...old,
        recipientIban: coreData.values.recipientIban,
        recipientName: coreData.values.recipientName,
        amount: coreData.values.amount,
        operationId: coreData.values.operationId,
        accounts
      }));
      coreData.setters.setAccountList(accounts);
    }
  });
  const disconnectFromWebsocketQuery = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.PDISCONNECT,
    returnObjectName: 'nonexistent',
    data: {},
    auto: false
  });

  const disconnectWS = useCallback(() => {
    disconnectFromWebsocketQuery.execute();
  }, [disconnectFromWebsocketQuery]);

  useSWCallbackSelectedEvent((data: any) => {
    if (coreData.values.state === State.BANK_AUTHENTICATE) {
      coreData.values.stateRef.send({ type: SEvent.COMPLETED });
    }

    if (data?.task === coreData.values.operationId) {
      const accounts = data.accounts.sort((a: any, b: any) => {
        return Number(a.balance) < Number(b.balance) ? 1 : -1;
      });
      const selected = data.selected;
      coreData.setters.setAccountList(accounts);
      if (selected) {
        coreData.setters.setAccount(selected);
      }
      setStateToSend((old: any) => ({
        ...old,
        recipientIban: coreData.values.recipientIban,
        recipientName: coreData.values.recipientName,
        amount: coreData.values.amount,
        operationId: coreData.values.operationId,
        accounts
      }));
    }
  });

  useSWCallbackErrorEvent((data: any) => {
    if (data.cancelOperation === null) {
      //console.log(data);
    } else {
      coreData.values.stateRef.send({ type: SEvent.FAIL });
    }
  });

  useSWCallbackSignEvent((data: any) => {
    if (coreData.values.state === State.NEED_UPGRADE) {
      return;
    }

    if (data !== undefined && data?.operationId === coreData.values.operationId) {
      const signEventData = data.piSignState;
      processSignEvent(signEventData);
      setStateToSend((old: any) => ({
        ...old,
        operationId: coreData.values.operationId,
        recipientIban: coreData.values.recipientIban,
        recipientName: coreData.values.recipientName,
        amount: coreData.values.amount,
        signState: signEventData
      }));
    }
  });

  useSWCallbackUpdateEvent((data: any) => {
    if (coreData.values.state === State.NEED_UPGRADE) {
      return;
    }

    if (data !== undefined && data?.operationId === coreData.values.operationId) {
      const authState = data.piAuthState;
      setStateToSend((old: any) => ({
        ...old,
        recipientIban: coreData.values.recipientIban,
        recipientName: coreData.values.recipientName,
        amount: coreData.values.amount,
        operationId: coreData.values.operationId,
        updateState: authState
      }));
      if (authState.url != undefined && authState.url.length > 0) {
        window.open(authState.url, '_blank')?.focus();
      }
      if (authState.state === 'timedOut') {
        coreData.values.stateRef.send({ type: SEvent.FAIL });
        //        coreData.setters.setState(State.ERROR);
      }
      if (authState.state === 'pending') {
        //        coreData.setters.setState(State.MONITOR_AUTH);
        if (authState.token.length > 0) {
          coreData.setters.setToken(authState.token);
        } else if (authState.token.length === 0 && authState.base64Data.length > 0) {
          coreData.setters.setToken(`data:image/png;base64,${authState.base64Data}`);
          coreData.setters.setAuthMode('image');
        } else {
          if (coreData.values.authMode === 'qr') {
            coreData.setters.setToken('');
          }
        }
      }
      if (authState.state === 'rejected') {
        coreData.values.stateRef.send({ type: SEvent.FAIL });
        //        coreData.setters.setState(State.ERROR);
      }

      if (authState.state === 'success') {
        coreData.values.stateRef.send({ type: SEvent.COMPLETED });
        //        coreData.setters.setState(State.ACCOUNT_CHOOSE);
      }
    }
  });

  useSWCallbackPaymentEvent((data: any) => {
    const currency = coreData.values.currency;
    if (currency == 'EUR') {
      const amountInSek = Number.parseFloat(data?.sek_deposit.toString());
      coreData.setters.setAmount(amountInSek);
      coreData.setters.setCurrency('SEK');
    }
  });

  useSWCallbackStateEvent((data: StateDataType) => {
    const operationProgress = data.operationProgress;
    const state = operationProgress.state;
    const currentTask = operationProgress.currentTask;
    const paymentState = operationProgress.paymentState;

    if (
      coreData.values.state === State.NEED_UPGRADE ||
      coreData.values.state === State.SUCCESS ||
      coreData.values.state === State.ERROR ||
      paymentState === 'needUpgrade'
    ) {
      return;
    }
    if (data !== undefined && data?.operationId === coreData.values.operationId) {
      if (state === 'failed' || state === 'cancelled') {
        //  coreData.setters.setErrorString(operationProgress.errorStr);
        coreData.values.stateRef.send({ type: SEvent.FAIL });
      }
      if (/sign (\w+) tx/.test(currentTask) && paymentState !== 'redo') {
        coreData.values.stateRef.send({ type: SEvent.NEXT });
      }
      if (state === 'awaitingFinality') {
        coreData.setters.setErrorString(operationProgress.errorStr);
        coreData.values.stateRef.send({ type: SEvent.COMPLETED });
      }
      //      const serverState = stateResolver(operationProgress);
      //      if (serverState.state !== GatewayState.UNKNOWN) {
      //        coreData.setters.setErrorString(serverState.errorMsg);
      //coreData.setters.setState(serverState.state);
      //      }
    }
  });

  useEffect(() => {
    if (searchParams.get('sessionId') !== null && searchParams.get('sessionData') !== null) {
      const sessionD = searchParams.get('sessionData');
      const jsonStringified = atob(sessionD!);
      const sessionData = JSON.parse(jsonStringified);
      dataRecovery(sessionData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    if (
      searchParams.get('sessionId') !== null &&
      connected === true &&
      dataRecovered === true &&
      signalsRecovered === false &&
      coreData.values.operationId.length > 0 &&
      recoverTimer === undefined
    ) {
      recoverTimer = setInterval(() => {
        console.log('RECOVERY TIME!!!');
        resumeSession.execute({ session_id: searchParams.get('sessionId') });
      }, 2000);
    }
  }, [connected, coreData.values.operationId, dataRecovered, resumeSession, searchParams, signalsRecovered]);

  switch (coreData.values.state) {
    case State.SUCCESS: {
      return (
        <center>
          <CentralFrame>
            <center>
              <Success />
            </center>
          </CentralFrame>
        </center>
      );
    }
    case State.ERROR: {
      return (
        <center>
          <CentralFrame>
            <center>
              <Fail />
            </center>
          </CentralFrame>
        </center>
      );
    }
    default: {
      return (
        <>
          {environment == 'dev' || environment == 'staging' ? (
            <>
              <Button
                onClick={() => {
                  resumeSession.execute({});
                }}
              >
                RESUME SESSION
              </Button>
              <Button
                onClick={() => {
                  signalCounter.execute({});
                }}
              >
                SIGNAL COUNTER
              </Button>
              <Button
                onClick={() => {
                  disconnectWS();
                }}
              >
                DISCONNECT
              </Button>
              <Button
                onClick={() => {
                  disconnectWS();
                  const currentUrl = new URL(window.location.href);
                  currentUrl.searchParams.set('sessionId', coreData.values.sessionId);
                  currentUrl.searchParams.set('sessionData', btoa(JSON.stringify(coreData.values.sessionData)));
                  window.open(currentUrl, '_blank')?.focus();
                }}
              >
                Recover session
              </Button>
              <Button
                onClick={() => {
                  disconnectWS();
                  const currentUrl = new URL(window.location.href);
                  currentUrl.searchParams.set('sessionId', coreData.values.sessionId);
                  currentUrl.searchParams.set('sessionData', btoa(JSON.stringify(coreData.values.sessionData)));
                  window.location.href = currentUrl.toString();
                }}
              >
                Recover session the same window
              </Button>
            </>
          ) : (
            <></>
          )}
          <center>
            <CentralFrame>
              <BoxContainer
                maxSteps={5}
                step={step}
                onBack={
                  cancelLocked
                    ? undefined
                    : async () => {
                        if (coreData.values.operationId !== undefined && coreData.values.operationId?.length > 0) {
                          cancelPayment.execute({ variables: { taskId: coreData.values.operationId } });
                        } else {
                          cancelPayment.execute({ variables: { txRef: coreData.values.refId } });
                        }

                        /*
                        if (
                          coreData.values.state === State.SELECT_BANK ||
                          coreData.values.state === State.PID_DISCOVERY
                        ) {
                          coreData.setters.setErrorString('Cancelled');
                          coreData.values.stateRef.send({ type: SEvent.FAIL });
                        } else {
                          cancelPayment.execute({ txRef: coreData.values.refId });
                        }
                          */
                      }
                }
                title={title?.[coreData.values.state]}
                desc={description?.[coreData.values.state]}
              >
                <GatewayWidget
                  cancelLock={(value) => {
                    if (value == 1) {
                      setCancelLocked(true);
                    } else {
                      setCancelLocked(false);
                    }
                  }}
                />
              </BoxContainer>
            </CentralFrame>
            <div>rev: {githash}</div>
          </center>
        </>
      );
    }
  }
};

export default GatewayData;
