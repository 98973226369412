import {
  AlandsBankIcon,
  BankHandelsbankenIcon,
  BankLansforsakringarIcon,
  BankNoreaIcon,
  BankSEBIcon,
  BankSwedIcon,
  DanskeIcon
} from 'shared-components/assets/icons';

export interface BaseProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

export interface BankProps {
  name: string;
  icon?: string;
  description?: string;
  healthy?: boolean;
  visible?: boolean;
}

export enum Banks {
  UNSET,
  REVOLUT,
  MANDATO
}

export const BankList: BankProps[] = [
  { name: 'seb', icon: BankSEBIcon, healthy: undefined, visible: true },
  {
    name: 'swedbank',
    icon: BankSwedIcon,
    healthy: undefined,
    visible: true
  },
  {
    name: 'handelsbank',
    icon: BankHandelsbankenIcon,
    healthy: undefined,
    visible: true
  },
  {
    name: 'nordea',
    icon: BankNoreaIcon,
    healthy: undefined,
    visible: true
  },
  {
    name: 'lfbank',
    icon: BankLansforsakringarIcon,
    healthy: undefined,
    visible: true
  },
  {
    name: 'danske',
    icon: DanskeIcon,
    healthy: undefined,
    visible: false
  },
  {
    name: 'alandsbank',
    icon: AlandsBankIcon,
    healthy: undefined,
    visible: false
  },
  {
    name: 'multitude',
    icon: undefined,
    description: 'Multitude bank',
    healthy: undefined,
    visible: true
  }
];
