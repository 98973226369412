import React, { useCallback } from 'react';
import { Button, Col, Row } from 'react-bootstrap';

import { useGateway } from 'providers/GatewayProvider';
import useSWQuery from 'shared-components/hooks/useSWQuery';
import { Method, Service } from 'shared-components/configuration';
import { CONFIRM_ACCOUNT } from 'shared-components/queries/mutations';
import WaitSpinner from 'shared-components/components/WaitSpinner';

import style from './style.module.scss';

export interface AccountProps {
  iban: string;
  balance: string;
  currency: string;
}

export const SelectAccount = () => {
  const coreData = useGateway();

  const handleConfirmAccountResponse = useCallback((_data: any) => {
    /*
      if (data !== undefined && data?.status === true) {
        coreData.setters.setState(GatewayState.SIGN);
      } else if (data !== undefined && data?.status === false) {
        coreData.setters.setState(GatewayState.ERROR);
      }
      */
  }, []);

  const confirmAccountQuery = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.GATEWAY,
    returnObjectName: 'confirmAccount',
    data: {
      signed_challenge: coreData.values.authToken,
      session_id: coreData.values.sessionId,
      query: CONFIRM_ACCOUNT,
      variables: {
        balance: '0',
        currency: 'SEK',
        iban: '',
        task: coreData.values.operationId
      }
    },
    auto: false,
    onResponse: handleConfirmAccountResponse
  });

  const onAccountClick = useCallback(
    (account: AccountProps) => {
      coreData.setters.setAccount(account);
      //      setConfirmationVisible(true);
      confirmAccountQuery.execute({
        variables: {
          balance: account.balance,
          currency: account.currency,
          iban: account.iban
        }
      });
    },
    [confirmAccountQuery, coreData.setters]
  );
  /*
  useEffect(() => {
    const accounts = coreData.values?.accountList;
    if (accounts?.length == 1) {
      onAccountClick(accounts[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coreData.values?.accountList]);
*/
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {coreData.values?.accountList?.length > 0 ? (
          coreData.values?.accountList?.map((account: any, key) => {
            return (
              <Button
                key={key}
                variant="outline-primary"
                className={style.accountButton}
                disabled={account.balance < coreData.values.amount}
                onTouchEnd={() => onAccountClick(account)}
                onMouseUp={() => onAccountClick(account)}
              >
                <Row style={{ margin: '0px' }}>
                  <Col xs={8} className={style.accountName}>
                    {account.name}
                    <br />
                    **** {account.iban.slice(-4)}
                  </Col>
                  <Col xs={4} className={style.accountBalance}>
                    {account.currency?.toUpperCase()}
                    <br />
                    {Number(account.balance)
                      .toFixed(2)
                      .replaceAll(/\B(?=(\d{3})+(?!\d))/g, ' ')
                      .replace('.', ',')}
                  </Col>
                </Row>
              </Button>
            );
          })
        ) : (
          <WaitSpinner>Loading account data</WaitSpinner>
        )}
      </div>
    </>
  );
};
